import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import hubspotHero2x from 'resources/img/hubspotHero2x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import MWT from './icons/MWT.png';
import VTLCR from './icons/VTLCR.png';
import LTO from './icons/LTO.png';
import WR from './icons/WR.png';
import LOSC from './icons/LOSC.png';
import ACV from './icons/ACV.png';
import Trapezoid from './icons/trapezoid.png';
import './styles.css';


const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;

  }
`;

const CalculatorContainer = styled.div`

    max-width: 1148px;
    background: white;
    box-shadow: 0px 1px 97px rgba(3, 12, 21, 0.1);
    border-radius: 20px;
    padding: 78px 75px;

    margin: 5rem auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 82px 22px;
    border-radius: 0px;
  }
`;


const CalculatorContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;
  flex-direction: column;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    justify-content: center;
    margin-bottom: 75px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;


const CalculatorField = styled.div`
  width: 100%;
  max-width: 360px;
  margin-bottom: 41px;
`;

const CalculatorFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
`;

const CalculatorFieldFooter = styled.div`
    display: flex;
    align-items: center;
`;

const CalculatorFieldLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170.3%;
    /* identical to box height, or 24px */

    margin: 0;
    color: #000000;
`;

const CalculatorFieldValue = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170.3%;
    /* identical to box height, or 24px */
    margin: 0;

    color: #000000;
`;

const CalculatorFieldIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 19px;
`;

const CalculatorFieldSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;

  background: #171B350A;
    border-radius: 10px;


  &::hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:active,&:focus {
        background: #0A7EF9;
        border: 1px solid rgba(0, 0, 0, 0.13);
        box-sizing: border-box;
        box-shadow: 0px 0px 9px #0A7EF9;
    }
  }
  
  &::-moz-range-thumb {
    width: 21px;
    height: 21px;
    background: #4CAF50;
    cursor: pointer;
  }


`;


const TrapezoidImage = styled.img`
  width: 100%;
  height: 494px;
  max-width: 546px;
  margin: auto;
  display: block;
`;


const TrapezoidContainer = styled.div`
  position: relative;
`;

const TrapezoidContent = styled.div`
  position: absolute;
  height:68px;
  left: 50%;
  transform: translateX(-50%);
  top: ${p => p.top};
`;

const TrapezoidLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 0 10px;
  min-width: 200px;
`;

const TrapezoidValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
`;

function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
const Calculator = props => {


    const [MWTValue, setMWTValue] = React.useState(0);
    const [VTLCRValue, setVTLCRValue] = React.useState(0);
    const [LTOValue, setLTOValue] = React.useState(0);
    const [WRValue, setWRValue] = React.useState(0);
    const [LOSCValue, setLOSCValue] = React.useState(0);
    const [ACVValue, setACVValue] = React.useState(0);

    const [calculatorTabActive, setCalculatorTabActive] = React.useState('minimum')



    const renderCalculatorField = ({label, value, icon, min, max, step, formattedValue, setValue}) => {

        return (
            <CalculatorField>
                <CalculatorFieldHeader>
                    <CalculatorFieldLabel>{label}</CalculatorFieldLabel>
                    <CalculatorFieldValue>{formattedValue}</CalculatorFieldValue>
                </CalculatorFieldHeader>
                <CalculatorFieldFooter>
                    <CalculatorFieldIcon src={icon} />
                    <CalculatorFieldSlider type="range" min={min} max={max} step={step} value={value} onChange={(event) => setValue(event.target.value)}/>
                </CalculatorFieldFooter>
            </CalculatorField>
        );
    }
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <CalculatorContainer>
                <Text maxWidth="600px" fontSize="36px" smFontSize="24px" fontWeight={700} textAlign="center" lineHeight="139.3%">We’ll let the numbers convince you.</Text>
                <Text maxWidth="600px" fontSize="18px" smFontSize="14px" fontWeight={400} textAlign="center" lineHeight="32px" opacity="0.8" marginTop="11px" mdMarginTop="43px" marginBottom="18px">Input your average conversion metrics, and we’ll unveil the pipeline that’s waiting to be generated—instantly.</Text>
                <Text maxWidth="600px" fontSize="12px" marginBottom="52px" mdMarginBottom="62px" fontWeight={400} textAlign="center" lineHeight="131%" opacity="0.5">
                This isn’t a generic ROI calculator, it’s a proposed return-on-investment based on the adoption of chat across your entire revenue organization.
                </Text>
                <Container>

                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <CalculatorContentWrapper>
                                {renderCalculatorField({
                                    label: 'Monthly Web Traffic',
                                    formattedValue: numberWithCommas(MWTValue),
                                    setValue: setMWTValue,
                                    value: MWTValue,
                                    icon: MWT,
                                    min: 0,
                                    max: 1000000,
                                    step: 500
                                })}
                                {renderCalculatorField({
                                    label: 'Visitor to Lead Conversion Rate (%)',
                                    formattedValue: numberWithCommas(VTLCRValue) + '%',
                                    setValue: setVTLCRValue,
                                    value: VTLCRValue,
                                    icon: VTLCR,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Lead to Opportunity (%)',
                                    formattedValue: numberWithCommas(LTOValue) + '%',
                                    setValue: setLTOValue,
                                    value: LTOValue,
                                    icon: LTO,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Win Rate (%)',
                                    formattedValue: numberWithCommas(WRValue) + '%',
                                    setValue: setWRValue,
                                    value: WRValue,
                                    icon: WR,
                                    min: 0,
                                    max: 100,
                                    step: 1
                                })}
                                {renderCalculatorField({
                                    label: 'Length of Sales Cycle (days)',
                                    formattedValue: `${LOSCValue} days`,
                                    setValue: setLOSCValue,
                                    value: LOSCValue,
                                    icon: LOSC,
                                    min: 0,
                                    max: 1000,
                                    step: 5
                                })}
                                {renderCalculatorField({
                                    label: 'Average Customer Value',
                                    formattedValue: `$${numberWithCommas(ACVValue)}`,
                                    setValue: setACVValue,
                                    value: ACVValue,
                                    icon: ACV,
                                    min: 0,
                                    max: 500000,
                                    step: 100
                                })}
                            </CalculatorContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>

                              <div class="wrapper">
                                <nav>
                                  <input type="radio" name="tab" id="home" checked={calculatorTabActive === 'minimum'} />
                                  <input type="radio" name="tab" id="inbox" checked={calculatorTabActive === 'anticipated'}/>
                                  <label for="home" class="home" onClick={() => setCalculatorTabActive('minimum')}>Minimum ROI</label>
                                  <label for="inbox" class="inbox" onClick={() => setCalculatorTabActive('anticipated')}>Anticipated ROI</label>
                                  <div class="tab">
                                  </div>
                                </nav>
                              </div>
                            <TrapezoidContainer>
                                <TrapezoidContent top="17px">
                                  <TrapezoidLabel>LEADS CREATED</TrapezoidLabel>
                                  <TrapezoidValue>+20000</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="120px">
                                  <TrapezoidLabel>PIPELINE GENERATED</TrapezoidLabel>
                                  <TrapezoidValue>+10000</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="238px">
                                  <TrapezoidLabel>OPPORTUNITIES ACCELERATED</TrapezoidLabel>
                                  <TrapezoidValue>+1000</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidContent top="358px">
                                  <TrapezoidLabel>CUSTOMERS</TrapezoidLabel>
                                  <TrapezoidValue>+20000</TrapezoidValue>
                                </TrapezoidContent>
                                <TrapezoidImage src={Trapezoid} />
                            </TrapezoidContainer>
                              <Text fontSize="24px" marginBottom="7px"  fontWeight={400} textAlign="center" lineHeight="29px" opacity="0.45">
                                MISSED REVENUE
                              </Text>
                              <Text fontSize="35px" marginBottom="15px"  fontWeight={400} textAlign="center" lineHeight="42px">
                                +$20,000
                              </Text>
                              <Text fontSize="14px" marginBottom="31px"  fontWeight={400} textAlign="center" lineHeight="170.3%" opacity="0.8">
                                Accelerate Sales Cycle by <b>3 Days</b>
                              </Text>
                              <CallToAction justify="center" submitLabel="Get Full Result"/>
                        </Grid>
                    </Grid>
                </Container>
            </CalculatorContainer>
        </Waypoint>
    );
};

Calculator.propTypes = {};

export default Calculator;
