import React from 'react';
import Hero from './Hero';
import Schedule from './Schedule';
import Footer from '../Home/Footer';
import Cases from './Cases';

const MarketingUseCase = props => {

  return (
    <div>
      <Hero />
      <Cases />
      <Schedule />
      <Footer />
    </div>
  );
};

MarketingUseCase.propTypes = {};

export default MarketingUseCase;
