import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import RightArrow from 'resources/icons/right-arrow.svg';
import theme from 'styles/theme'; 
import Carousel from 'nuka-carousel';
import Back from 'resources/img/back';
import Next from 'resources/img/next';


const ViewBlog = styled.a`
    border: none;
    background: none;
    color: #0a7ef9;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    &:focus {
        outline: none;
    }
`;

const BlogMobileWrapper = styled.div`
    display: none;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      display: block;
    }
`;


const BlogWrapper = styled.div`

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    display: none;
    padding: 1rem 0;

  }
`;

const BlogActionWrapper = styled(Text)`
    color: ${p => p.theme.palette.blue.two};
    margin-left: auto;
    margin-right: 2rem;

`;

const BlogRightArrowIcon = styled.img`
  height: 10px;
  width: 10px;
`;

const BlogContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding: 60px 0;
    background-color: ${p => p.theme.palette.background.three};

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        padding: 65px 24px;
    }
`;


const BlogCard = styled.a`
    display: block;
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 20px 20px #0000000D;
    border-radius: 10px;
    padding-bottom: 30px;
    overflow: hidden;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        max-width: 95%;
    }
`;

const BlogTtile = styled(Text)`
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;


const NavButtonWrapper = styled.div`
    margin: 0;
    top: 20px;
    display: flex;
    position: absolute;
`;

const NavButton = styled.div`
  cursor: pointer;
  padding-right: 0.5rem;
  transition: transform 0.1s ease-in-out;
  will-change: transform;
z-index: 111;
  &:hover {
    transform: scale(1.2);
  }
`;

const Blog = props => {
    const [slidesToShow, setslidesToShow] = React.useState(1);


    const carouselSettings = {
        slidesToShow,
        slidesToScroll: 1,
        cellAlign: "center",
        cellSpacing: 20,
        slideWidth: 1
    };

    const renderNavButtons = carouselProp => {
        return (
            <NavButtonWrapper>
                <NavButton onClick={carouselProp.previousSlide}>
                    <Back height="25px" width="15px" />
                </NavButton>
                <NavButton onClick={carouselProp.nextSlide}>
                    <Next height="25px" width="15px" />
                </NavButton>
            </NavButtonWrapper>
        );
    };
    
    return (
        <Element name="Blog">
            <BlogContainer>
                <Waypoint
                    scrollableAncestor={window}
                    topOffset="10%"
                    bottomOffset="35%"
                >
                    <Container>
                    <Text fontSize="40px" smFontSize="30px" fontWeight={700} textAlign="center" maxWidth="620px" marginBottom="60px">Latest from our <span>blog:</span></Text>

                        
                        <BlogWrapper>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <BlogCard target='_blank' href="https://blog.insent.ai/your-buyers-guide-to-choosing-the-right-chat-platform">
                                        <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/Buyers%20Guide%20How%20To%20Choose%20The%20Right%20Chatbot-5.png' src='https://blog.insent.ai/hubfs/Buyers%20Guide%20How%20To%20Choose%20The%20Right%20Chatbot-5.png' />
                                        <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                            Lead Qualification
                                        </Text>
                                        <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                            Your Buyer's Guide to Choosing the Right Chat(bot) Platform
                                        </BlogTtile>
                                    </BlogCard>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <BlogCard target='_blank' href="https://blog.insent.ai/microsoft-teams-for-insent-enhancing-your-communication">
                                        <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/Google%20Drive%20Integration/MS%20Teams%20Integration%20Blog%20Post-2.png' src='https://blog.insent.ai/hubfs/Google%20Drive%20Integration/MS%20Teams%20Integration%20Blog%20Post-2.png' />
                                        <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                            Conversational Marketing
                                </Text>
                                        <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                            Microsoft Teams for Insent: Enhancing Your Communication

                                </BlogTtile>
                                    </BlogCard>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <BlogCard target='_blank' href="https://blog.insent.ai/accelerate-your-speed-to-connect-and-turn-inbound-leads-into-opportunities">
                                        <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/BLOG%20POST%20Accelerate%20Your%20Speed%20to%20Connect%20And%20Turn%20Marketing%20Leads%20into%20Opportunities-2.png' src='https://blog.insent.ai/hubfs/BLOG%20POST%20Accelerate%20Your%20Speed%20to%20Connect%20And%20Turn%20Marketing%20Leads%20into%20Opportunities-2.png' />
                                        <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                            Lead Qualification
                                            </Text>
                                        <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                            Accelerate Your Speed to Connect And Turn Inbound Leads into Opportunities
                                </BlogTtile>
                                    </BlogCard>
                                </Grid>
                                
                            </Grid>
                        </BlogWrapper>
                        <BlogMobileWrapper>
                            <Carousel
                                renderCenterLeftControls={props => null}
                                renderCenterRightControls={props => null}
                                renderBottomCenterControls={props => null}
                                renderBottomLeftControls={props => renderNavButtons(props)}
                                {...carouselSettings}
                            >
                                <BlogCard target='_blank' href="https://blog.insent.ai/your-buyers-guide-to-choosing-the-right-chat-platform">
                                    <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/Buyers%20Guide%20How%20To%20Choose%20The%20Right%20Chatbot-5.png' src='https://blog.insent.ai/hubfs/Buyers%20Guide%20How%20To%20Choose%20The%20Right%20Chatbot-5.png' />
                                    <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                        Lead Qualification
                                        </Text>
                                    <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                        Your Buyer's Guide to Choosing the Right Chat(bot) Platform
                                        </BlogTtile>
                                </BlogCard>
                                <BlogCard target='_blank' href="https://blog.insent.ai/microsoft-teams-for-insent-enhancing-your-communication">
                                    <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/Google%20Drive%20Integration/MS%20Teams%20Integration%20Blog%20Post-2.png' src='https://blog.insent.ai/hubfs/Google%20Drive%20Integration/MS%20Teams%20Integration%20Blog%20Post-2.png' />
                                    <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                        Conversational Marketing
                                </Text>
                                    <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                        Microsoft Teams for Insent: Enhancing Your Communication

                                </BlogTtile>
                                </BlogCard>
                                <BlogCard target='_blank' href="https://blog.insent.ai/accelerate-your-speed-to-connect-and-turn-inbound-leads-into-opportunities">
                                    <Image height="200px" width="100%" hdSrc='https://blog.insent.ai/hubfs/BLOG%20POST%20Accelerate%20Your%20Speed%20to%20Connect%20And%20Turn%20Marketing%20Leads%20into%20Opportunities-2.png' src='https://blog.insent.ai/hubfs/BLOG%20POST%20Accelerate%20Your%20Speed%20to%20Connect%20And%20Turn%20Marketing%20Leads%20into%20Opportunities-2.png' />
                                    <Text fontSize="12px" fontWeight={400} textAlign="left" marginBottom="10px" marginTop="20px" marginLeft="20px" marginRight="20px">
                                        Lead Qualification
                                            </Text>
                                    <BlogTtile fontSize="20px" fontWeight={700} textAlign="left" marginLeft="20px" marginRight="20px">
                                        Accelerate Your Speed to Connect And Turn Inbound Leads into Opportunities
                                </BlogTtile>
                                </BlogCard>
                            </Carousel>
                        </BlogMobileWrapper>
                        
                            <Text fontSize="14px" mdTextAlign="center" smTextAlign="right"  textAlign="right" fontWeight={700} marginLeft="auto" smMarginRight="0" mdMarginRight="2rem" color={theme.palette.blue.two} marginBottom="25px" marginTop="25px">
                            <ViewBlog target='_blank' href="https://blog.insent.ai/">View all <BlogRightArrowIcon src={RightArrow} alt="vew all" /></ViewBlog> 
                        </Text>
                    </Container>
                </Waypoint>
            </BlogContainer>
        </Element>
    );
};

Blog.propTypes = {};

export default Blog;