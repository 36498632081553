import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import hubspotHero from 'resources/img/hubspotHero.png';
import roiAction3x from 'resources/img/roiAction3x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Button from 'ui/components/Button';


const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const ActionContainer = styled.div`
  overflow: hidden;
  padding: 0 ;

  background: white;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 6rem 0;

    button {
      text-align: center;
    }
  }
`;


const ActionContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;


const ActionFooter = styled.div`
  display: flex;
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    flex-direction: column;
  }

  
`;

const ActionFooterLeft = styled.div`
    display: flex;
    justify-content: flex-end;
    background: #0A7EF9;
    width: 100%;
    padding: 41px 85px;

    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      justify-content: center;
      padding: 41px;
    }
`;

const ActionFooterRight = styled.div`
    background: #65D0FB;
    width: 100%;
    padding: 41px 85px;
    @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
      display: flex;
      padding: 41px;

      justify-content: center;
    }
`;

const Content = styled.div`
  max-width: 574px;
`;

const Action = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <ActionContainer>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <ActionContentWrapper>
                                  <Text maxWidth="438px" marginLeft="0" mdMarginLeft="auto" fontSize="36px" smFontSize="24px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%">
                                    See how live conversations can turn your website into your best sales channel
                                  </Text>
                                  <Text maxWidth="438px" mdMarginLeft="auto" fontSize="18px" smFontSize="14px" mdTextAlign="center" smMarginTop="32px" marginTop="25px" marginBottom="30px" smMarginBottom="25px" maxWidth="530px" opacity="0.8" lineHeight="170.3%" marginLeft="0">
                                    Get a personalized walkthrough of Insent’s platform and its integrations with your existing tools so you can accelerate deals, reduce your sales cycle, and win more customers.
                                  </Text>
                                  <Button height="50px" fontWeight="600" fontSize="14px" padding="15px 40px">See Insent On Your Website</Button>
                            </ActionContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Image height="100%" width="100%" maxWidth="610px" margin="0 auto" mdPaddingTop="60px" mdPaddingBottom="60px" display="flex" hdSrc={roiAction3x} src={roiAction3x} />
                        </Grid>
                    </Grid>
                </Container>
                <ActionFooter>
                    <ActionFooterLeft>
                        <Content>
                          <Text marginLeft="0" mdMarginLeft="auto" maxWidth="300px" color="white" fontSize="24px" smFontSize="18px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%" marginBottom="27px" mdMarginBottom="19px">
                              All the Ways You Can Use Insent → 
                          </Text>
                          <Text marginLeft="0" mdMarginLeft="auto" maxWidth="464px" color="white" fontSize="18px" fontWeight={400} mdTextAlign="center" lineHeight="170.3%" smFontSize="12px">
                              Discover all the ways your entire revenue team can use Insent to power your demand generation, ABM, and sales acceleration strategy.
                          </Text>
                        </Content>
                    </ActionFooterLeft>
                    <ActionFooterRight>
                        <Content>
                        <Text marginLeft="0" mdMarginLeft="auto" maxWidth="300px" color="white" fontSize="24px" smFontSize="18px" fontWeight={700} mdTextAlign="center" lineHeight="139.3%" marginBottom="27px" mdMarginBottom="19px">
                            Talk to a Conversational Advisor → 
                        </Text>
                        <Text marginLeft="0" mdMarginLeft="auto" maxWidth="464px" color="white" fontSize="18px" fontWeight={400} mdTextAlign="center" lineHeight="170.3%" smFontSize="12px">
                            Connect with one of our conversational advisors who can recommend the appropriate conversational strategy for your GTM plan. 
                        </Text>
                        </Content>
                    </ActionFooterRight>
                </ActionFooter>
            </ActionContainer>
        </Waypoint>
    );
};

Action.propTypes = {};

export default Action;
