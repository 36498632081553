import React from 'react';




const Test = (pros) => {


    return (
        <div>
            <h1 >Text</h1>

            <h4 class="h4-class">.h4-class</h4>
            <h4 id="h4-id">#h4-id</h4>


        <h1>Form</h1>
            <form class="row g-3">
                <div class="col-auto">
                    <label for="staticEmail2" class="visually-hidden">Email</label>
                    <input type="text" readonly class="form-control-plaintext" id="staticEmail2" value="email@example.com" />
                </div>
                <div class="col-auto">
                    <label for="inputPassword2" class="visually-hidden input-class">Password</label>
                    <input type="password" class="form-control" id="inputPassword2" placeholder="Password" />
                </div>
                <div class="col-auto">
                    <button type="submit" id="form-btn-id-class" class="mr-3 btn btn-primary mb-3  form-btn-class-id">#form-btn-id-class || .form-btn-class-id</button>
                    <button type="submit" id="form-btn-id" class="mr-3 btn btn-primary mb-3">#form-btn-id</button>
                    <button type="submit" class="btn btn-primary mb-3 form-btn-class">.form-btn-class</button>
                </div>
            </form>

            <h1>Grid buttons</h1>

            <div class="container">
                <div class="row">
                    <div class="col">
                    <button type="button" class="btn btn-primary btn-class">btn-class</button>
                    </div>
                    <div class="col">
                    <button type="button" class="btn btn-danger" id="btn-id">.#btn-id</button>
                    </div>
                </div>
            </div>

            <h1>Grid A links</h1>


            <div class="container">
                <div class="row">
                    <div class="col">
                    <a href="https://insent.ai" class="btn btn-primary a-class">.a-class</a>
                    </div>
                    <div class="col">
                    <a href="https://insent.ai" class="btn btn-danger" id="a-id">#a-id</a>
                    </div>
                </div>
            </div>


            <h1>A tag</h1>
            <a href="https://insent.ai" class="a-class">.a-class</a>
            <a href="https://insent.ai"  id="a-id">#a-id link</a>
        </div>
    );
};


export default Test;