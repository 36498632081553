import React from 'react';
import { Element } from 'react-scroll';
import { Container, Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import Text from 'ui/components/Text';
import Image from 'ui/components/Image';
import theme from 'styles/theme';
import insentBotIcon from 'resources/img/insentBotIcon.png';
import insentBotIcon2x from 'resources/img/insentBotIcon2x.png';

const SolutionContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  background-color: rgba(245, 245, 245, 0.5);

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    padding: 36px 0;
  }
`;

const SolutionLogoContainer = styled.div`
  position: absolute;
  right: 0;
  top: -45px;
`;

const SolutionPreHeader = styled.div`
  color: #191919;
  // border:1px solid red;
  text-align: center;
  font-weight: 100;
  font-size: 34px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 80px;
  font-stretch: normal;
  line-height: 1.2;

  span {
    // font-style: italic;
    font-weight: 700;
    color: #0a7ef9;
  }
`;

const SolutionCardWrapper = styled.div`
  padding: 60px 40px;
  background: ${(p) => p.theme.palette.background.two + '1A'};
  border-radius: 5px;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    padding: 30px 20px;
  }
`;

const SolutionCard = styled(({ bgColor, ...others }) => (
  <animated.div {...others} />
))`
  background-color: ${(p) => p.bgColor || 'white'};
  padding: ${(p) => p.padding || '60px 30px'};
  border-radius: 20px;
  position: relative;

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
    padding: ${(p) => p.padding || '30px 20px'};
  }
`;
const Solution = (props) => {
  const [isContentVisible, setContentIsVisible] = React.useState(false);
  const leftCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
  });
  const rightCardStyleProps = useSpring({
    opacity: isContentVisible ? 1 : 0,
    transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
  });

  return (
    <Element name="Why Insent">
      <SolutionContainer>
        <Waypoint
          scrollableAncestor={window}
          topOffset="10%"
          bottomOffset="35%"
          onEnter={() => setContentIsVisible(true)}
          onLeave={() => setContentIsVisible(false)}
        >
          <Container>
            <Text
              fontSize="40px"
              smFontSize="30px"
              fontWeight={700}
              textAlign="center"
              maxWidth="700px"
              marginBottom="61px"
            >
              Breakthrough with a <span>humanized approach</span> to
              conversational marketing
            </Text>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <SolutionCardWrapper>
                  <SolutionCard style={leftCardStyleProps}>
                    <Text fontSize="24px" fontWeight={700}>
                      Without Insent.ai
                    </Text>
                    <Text fontSize="18px" fontWeight={400} marginTop="5px">
                      Rely on homepage call-to-actions and static content
                      offers.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      Website status quo
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Continue spending more money to drive website traffic with
                      the current conversion rate.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      Let your buyers leave
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Let your prospects bounce off your website before you can
                      help them - or even know they’re there.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      Failed attempts to reaching accounts
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Once you’ve missed them on your website, continue reaching
                      out via phone, email or LinkedIn.
                    </Text>
                  </SolutionCard>
                </SolutionCardWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <SolutionCardWrapper>
                  <SolutionCard style={rightCardStyleProps}>
                    <SolutionLogoContainer>
                      <Image
                        height="110px"
                        width="110px"
                        hdSrc={insentBotIcon2x}
                        src={insentBotIcon}
                      />
                    </SolutionLogoContainer>
                    <Text fontSize="24px" fontWeight={700}>
                      With Insent.ai
                    </Text>
                    <Text fontSize="18px" fontWeight={400} marginTop="5px">
                      Talk to your target accounts now, while you have their
                      attention.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      Real-time, qualified notifications
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Notify your sales team the moment qualified prospects
                      visit your website.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      Start a conversation — Integrate Insent with your MAP/CRM
                      and get engagement data that helps you craft the perfect
                      outreach.
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Integrate our bot with your MAP/CRM and get engagement
                      data that helps you craft the perfect outreach.
                    </Text>

                    <Text
                      fontSize="20px"
                      fontWeight={700}
                      color={theme.palette.text.five}
                      marginTop="2rem"
                      marginBottom="2rem"
                    >
                      A smarter way to generate pipeline
                    </Text>

                    <Text fontSize="18px" fontWeight={400}>
                      Have an immediate impact on the metrics that matter: sales
                      opportunities.
                    </Text>
                  </SolutionCard>
                </SolutionCardWrapper>
              </Grid>
            </Grid>
          </Container>
        </Waypoint>
      </SolutionContainer>
    </Element>
  );
};

Solution.propTypes = {};

export default Solution;
