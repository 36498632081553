import React from 'react';
import Hero from './Hero';
import Footer from '../Home/Footer';
import { Helmet } from "react-helmet";

const Home = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content=" Streamline your account-based marketing efforts with the AI chatbot that helps create authentic connections between prospects, marketing, and sales. Book a demo and learn more about Insent today." />
        <meta name="keywords" content="account based marketing, MQL/MQA, account based sales, abm marketing" />
        <title>Smarter Account-Based Marketing and Sales  | Insent.ai Bot</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Hero />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
