import React from "react";
import styled from "styled-components";
import logo from "resources/img/Logo.png";
import { useSpring, animated } from "react-spring";
import Button from "ui/components/Button";
import Input from "ui/components/Input";
import { Formik } from "formik";
import LeftLine from "resources/img/leftLine.js";
import RightLine from "resources/img/rightLine.js";
import Snackbar from "@material-ui/core/Snackbar";
import request from "utils/request.js";
import Alert from "@material-ui/lab/Alert";
import * as microsoftTeams from "@microsoft/teams-js";

const PageContainer = styled.div`
    height: 100vh;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
`;

const Logo = styled.img`
    width: 144px;
    height: 47px;
    margin-top: 1rem;
`;

const LoginDesc = styled.p`
    font-size: 16px;
    line-height: 1.22;
    color: #757575;
    max-width: 532px;
    text-align: left;
    padding-bottom: 0.5rem;
    margin: 0 auto;

    @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
        font-size: 12px;
    }
`;

const LeftWrapper = styled(animated.div)`
    position: absolute;
    left: -100px;
    bottom: -100px;
    z-index: -1;

    svg {
        height: 500px;
        width: 700px;

        @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
            height: 500px;
            width: 450px;
        }

        @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
            display: none;
        }
    }
`;

const RightWrapper = styled(animated.div)`
    position: absolute;
    right: -60px;
    bottom: -100px;
    z-index: -1;

    svg {
        height: 700px;
        width: 500px;

        @media only screen and (max-width: ${(p) => p.theme.breakpoints.md - 1}px) {
            height: 600px;
            width: 400px;
            display: none;
        }
    }
`;

const LoginWrapper = styled.div`
    background: white;
    padding: 2rem 2rem 1rem;
    min-width: 500px;
    position: relative;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); */
    margin: 0 auto;
    @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
        min-width: 0;
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    text-align: right;
    max-width: 313px;
    margin: 1rem 0 1rem auto;
`;

const LoginHeader = styled.h2`
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    color: #191919;
    padding-bottom: 1rem;
    text-align: left;

    span {
        text-decoration: underline;
        text-decoration-color: #0a7ef9;
    }

    @media only screen and (max-width: ${(p) => p.theme.breakpoints.sm - 1}px) {
        max-width: unset;
    }
`;

export const MSTeamsConfig = () => {
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);

    const intervalId = setInterval(function () {
        let cookiePopup = document.getElementById("hs-eu-cookie-confirmation");
        if (cookiePopup) {
            cookiePopup.remove();
            if (intervalId) clearInterval(intervalId);
        }
    }, 100);

    const handleSubmit = async (value) => {
        try {
            const response = await request({
                method: "get",
                endpoint: "health",
                config: {},
                companyName: value.company,
            })
                .then((data) => {
                    microsoftTeams.settings.setValidityState(true);
                    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
                        microsoftTeams.settings.setSettings({
                            websiteUrl: `https://${value.company}.insent.ai`,
                            contentUrl: `https://${value.company}.insent.ai/login?redirectUrl=%2Fteams-success`,
                            entityId: "insentLogin",
                            suggestedDisplayName: "Insent",
                        });
                        saveEvent.notifySuccess();
                    });
                    setVerified(true);
                })
                .catch((e) => {
                    console.log("err", e);
                    setOpen(!open);
                });
        } catch {
            setOpen(!open);
        }
    };

    return (
        <PageContainer>
            <Logo src={logo} />
            {microsoftTeams.initialize()}
            <br />
            <LoginDesc>Hello, Welcome to Insent !! Insent is your human-first conversation bot, helping you to chat with the qualified prospects on your website.</LoginDesc>

            <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(!open)}>
                <Alert onClose={() => setOpen(!open)} severity="error">
                    Company name not found.
                </Alert>
            </Snackbar>
            <LoginWrapper>
                <LoginHeader>Sign in</LoginHeader>
                <LoginDesc>Enter your company name below.</LoginDesc>
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        if (values) {
                            handleSubmit(values);
                        }
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Input width="100%" type="text" name="company" onChange={handleChange} onBlur={handleBlur} value={values.company} placeholder="eg. insent" required />
                            <ButtonContainer>{!verified && <Button>Continue</Button>}</ButtonContainer>
                        </form>
                    )}
                </Formik>
            </LoginWrapper>
            <div>
                Note: <i>If you don't have an Insent account, reach our sales team at hello@insent.ai. </i>
            </div>

        </PageContainer>
    );
};

export default MSTeamsConfig;
