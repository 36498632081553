import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import styled from 'styled-components';
import theme from 'styles/theme';


const ImageContainer = styled.img`
    height: ${p => p.height || `50px`};
    width: ${p => p.width || `50px`};
    margin: ${p => p.margin || `0px`};
    padding: ${p => p.padding || `0px`};
    padding-top: ${p => p.paddingTop};
    padding-left: ${p => p.paddingLeft};
    padding-bottom: ${p => p.paddingBottom};
    padding-right: ${p => p.paddingRight};
    margin-top: ${p => p.marginTop};
    margin-left: ${p => p.marginLeft};
    margin-bottom: ${p => p.marginBottom};
    margin-right: ${p => p.marginRight};
    display: ${p => p.display};
    max-width: ${p => p.maxWidth || `100%`};
    max-height: ${p => p.maxHeight || `100%`};
    z-index: ${p => p.zIndex || `5`};
    position: ${p => p.position};



    @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
        padding-top: ${p => p.lgPaddingTop};
        padding-left: ${p => p.lgPaddingLeft};
        padding-bottom: ${p => p.lgPaddingBottom};
        padding-right: ${p => p.lgPaddingRight};
        margin-top: ${p => p.lgMarginTop};
        margin-left: ${p => p.lgMarginLeft};
        margin-bottom: ${p => p.lgMarginBottom};
        margin-right: ${p => p.lgMarginRight};
        max-width: ${p => p.lgMaxWidth};
        display: ${p => p.lgDisplay};
      }
    
      @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
        padding-top: ${p => p.mdPaddingTop};
        padding-left: ${p => p.mdPaddingLeft};
        padding-bottom: ${p => p.mdPaddingBottom};
        padding-right: ${p => p.mdPaddingRight};
        margin-top: ${p => p.mdMarginTop};
        margin-left: ${p => p.mdMarginLeft};
        margin-bottom: ${p => p.mdMarginBottom};
        margin-right: ${p => p.mdMarginRight};
        max-width: ${p => p.mdMaxWidth};
        display: ${p => p.mdDisplay};
      }
    
      @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
        padding-top: ${p => p.smPaddingTop};
        padding-left: ${p => p.smPaddingLeft};
        padding-bottom: ${p => p.smPaddingBottom};
        padding-right: ${p => p.smPaddingRight};
        margin-top: ${p => p.smMarginTop};
        margin-left: ${p => p.smMarginLeft};
        margin-bottom: ${p => p.smMarginBottom};
        margin-right: ${p => p.smMarginRight};
        max-width: ${p => p.smMaxWidth};
        display: ${p => p.smDisplay};
        width: ${p => p.smWidth};
      }
    
`;



export const Image  = ({height= '50px', width = '50px', hdSrc, src, ...props}) => {
    const dominantImageColor = theme.palette.background.one;



    const placeholder = (
        <div
            style={{ backgroundColor: dominantImageColor, height: height, width: width }}
        />
    );
    return (
        <ProgressiveImage src={hdSrc} placeholder={src}>
            {(src, loading) => {
                return loading ? placeholder : <ImageContainer height={height} width={width} src={src} alt="an image" {...props}/>;
            }}
        </ProgressiveImage>
    );  
}

export default Image;


