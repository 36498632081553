import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const Button = styled(animated.button)`
  z-index: 1;
  border-radius: 24px;
  height: ${p => p.height ? p.height : '37px'};
  padding: ${p => p.padding ? p.padding : '0 26px'};
  font-size: ${p => p.fontSize ? p.fontSize : '12px'};
  font-weight: ${p => p.fontWeight ? p.fontWeight : '400'};
  background-color: #0a7ef9;
  border-color: #0a7ef9;
  border-width: 0;
  color: white;
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  ${p => p.width && `width: ${p.width}` }
  margin-top: ${p => p.marginTop};
  margin-left: ${p => p.marginLeft};
  margin-bottom: ${p => p.marginBottom};
  margin-right: ${p => p.marginRight};
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: 0;
  }

  ${p => {
    const variant = p.variant;

    if (variant === 'outlined') {
      return css`
        border-width: 1px;
        background-color: transparent;
        color: #0a7ef9;
      `;
    } else if (variant === 'link') {
      return css`
        border: none;
        background-color: transparent;
        color: #0a7ef9;
      `;
    }
  }}



  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    font-size: ${p => p.lgFontSize ? p.lgFontSize : p.fontSize};
    padding-top: ${p => p.lgPaddingTop};
    padding-left: ${p => p.lgPaddingLeft};
    padding-bottom: ${p => p.lgPaddingBottom};
    padding-right: ${p => p.lgPaddingRight};
    margin-top: ${p => p.lgMarginTop};
    margin-left: ${p => p.lgMarginLeft};
    margin-bottom: ${p => p.lgMarginBottom};
    margin-right: ${p => p.lgMarginRight};
    text-align: ${p => p.lgTextAlign};
    max-width: ${p => p.lgMaxWidth};
    display: ${p => p.lgDisplay};
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    font-size: ${p => p.mdFontSize ? p.mdFontSize : p.fontSize};
    padding-top: ${p => p.mdPaddingTop};
    padding-left: ${p => p.mdPaddingLeft};
    padding-bottom: ${p => p.mdPaddingBottom};
    padding-right: ${p => p.mdPaddingRight};
    margin-top: ${p => p.mdMarginTop};
    margin-left: ${p => p.mdMarginLeft};
    margin-bottom: ${p => p.mdMarginBottom};
    margin-right: ${p => p.mdMarginRight};
    text-align: ${p => p.mdTextAlign};
    max-width: ${p => p.mdMaxWidth};
    display: ${p => p.mdDisplay};
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    font-size: ${p => p.smFontSize ? p.smFontSize : p.fontSize};
    padding-top: ${p => p.smPaddingTop};
    padding-left: ${p => p.smPaddingLeft};
    padding-bottom: ${p => p.smPaddingBottom};
    padding-right: ${p => p.smPaddingRight};
    margin-top: ${p => p.smMarginTop};
    margin-left: ${p => p.smMarginLeft};
    margin-bottom: ${p => p.smMarginBottom};
    margin-right: ${p => p.smMarginRight};
    text-align: ${p => p.smTextAlign};
    max-width: ${p => p.smMaxWidth};
    display: ${p => p.smDisplay};
  }
`;

Button.displayName = 'Button';

Button.propTypes = {
  // variant: PropTypes.oneOf(['contained', 'outlined']),
};

export default Button;
