import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Lines from 'resources/img/linesXC.svg';
import './styles.css';

import Header from '../Home/Header.js';
import Footer from '../Home/Footer.js';


const HeaderWrapper = styled.div`
    min-height: 6rem;
`;
const PrivacyPolicyContainer = styled.div`
  background-image: url(${Lines});
  padding: 3rem 0;
`;

const ParagraphTag = styled.p`
    margin: 0;
    color: #000000;
    font-size: 11pt;
    text-align: justify;
    margin-bottom: 0.5rem;
`;

const ListTag = styled.li`
    color: #000000;
    font-size: 11pt;
    text-align: justify;

`;

const PrivacyPolicy = props => {
  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
    <PrivacyPolicyContainer>
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} lg={8}>
            <ParagraphTag class="c1"><span class="c9">Insent Inc. Privacy Policy</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Effective Date: November 21, 2019</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">Insent Inc. (&ldquo;Insent&rdquo;, &lsquo;Insent.ai, &ldquo;us&rdquo;,
            &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://insent.ai/&amp;sa=D&amp;ust=1580289059295000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=https://insent.ai/&amp;sa=D&amp;ust=1580289059295000">https://insent.ai/</a></span><span
                      class="c7">&nbsp;(the &ldquo;Website&rdquo;) and</span><span class="c7"><a class="c2"
                        href="https://www.google.com/url?q=https://insent.ai/&amp;sa=D&amp;ust=1580289059296000">&nbsp;</a></span><span
                          class="c4"><a class="c2"
                            href="https://www.google.com/url?q=https://insent.ai/&amp;sa=D&amp;ust=1580289059296000">https://app.insent.ai/</a></span><span
                              class="c0">&nbsp;(the &ldquo;App&rdquo;) (together, or individually, the &ldquo;Services&rdquo;). This
privacy policy (&ldquo;Privacy Policy&rdquo;) describes our collection, use, and disclosure of the following
            categories of information:</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7 c11">Information that we process as a &ldquo;controller&rdquo; (&ldquo;Customer
            Data&rdquo;)</span><span class="c7">. If you visit our Website, use our App, or receive email communications
                    from us, then this privacy policy (&ldquo;Privacy Policy&rdquo;) applies to you (as a
                    &ldquo;Customer&rdquo;) and describes how we collect, use, and disclose your information. Under applicable
                    law, we are considered the be the &ldquo;data controller&rdquo; of your information. You can learn more
                    about our compliance with the European Union (&ldquo;EU&rdquo;) General Data Protection Regulation
            (&ldquo;GDPR&rdquo;)</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://docs.google.com/document/d/1JXWoNHKMIIkbphrHLTEA6toYQokGG0nhCgLqjnjsxts/edit%23heading%3Dh.iq0tldjyznsj&amp;sa=D&amp;ust=1580289059297000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://insent.ai/gdpr">here</a></span><span
                      class="c7">. By using our Services, you agree to our processing of your information in accordance with this
            Privacy Policy and our</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://docs.google.com/document/d/18MswOoQ5MIgfQYf6wMfYzvFz3bSDT067UpagN5YAEJg/edit&amp;sa=D&amp;ust=1580289059298000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://insent.ai/terms-of-service">Terms
                of Service</a></span><span class="c0">.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7 c11">Information that we process as a &ldquo;processor&rdquo; (&ldquo;Service
            Data&rdquo;)</span><span class="c7">. If you are a visitor to or user of a website, application, or service
                    on which a Customer uses our Services, then any information that you provide through our Services is subject
                    to the Customer&rsquo;s privacy policy and applicable terms. When we provide our Services to a Customer, we
                    act as a &ldquo;data processor&rdquo;, and the Customer is considered to be the &ldquo;data
            controller&rdquo;. You can learn more about our Services</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://docs.google.com/document/d/18MswOoQ5MIgfQYf6wMfYzvFz3bSDT067UpagN5YAEJg/edit&amp;sa=D&amp;ust=1580289059300000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=https://docs.google.com/document/d/18MswOoQ5MIgfQYf6wMfYzvFz3bSDT067UpagN5YAEJg/edit&amp;sa=D&amp;ust=1580289059300000">here</a></span><span
                      class="c0">.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7 c11">Overview of this Privacy Policy</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">1.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Customer Data
            Collection, Use, and Retention</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">2.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Service Data
            Collection, Use, and Retention</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">3.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Data
            Disclosure</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">4.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Automatically
            Collected Data</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">5.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Interest-Based
            Advertising</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">6.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Do-Not-Track
            Disclosure</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">7.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Your Options and
            Rights</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">8.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">Security</span>
            </ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">9.</span><span class="c12">&nbsp; &nbsp; </span><span class="c0">International
            Transfer of Personal Data</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">10.</span><span class="c12">&nbsp;</span><span class="c0">Links to Other
            Services</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">11.</span><span class="c12">&nbsp;</span><span class="c0">Children&rsquo;s
            Privacy</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">12.</span><span class="c12">&nbsp;</span><span class="c0">Changes to this Privacy
            Policy</span></ParagraphTag>
            <ParagraphTag class="c1 c8"><span class="c7">13.</span><span class="c12">&nbsp;</span><span class="c0">How to Contact Us</span>
            </ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">1. Customer Data Collection, Use, and Retention</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are a Customer, we may ask you to provide us with certain information, such as
                    your name, postal address, email address, and employer. You do not have a statutory or contractual
                    obligation to provide us with your information; however, certain information may be required in order to
            enter into a contract with us.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We may also collect information from third parties, such as other users of our
                    Services (e.g., we may receive your email address from a Customer that provides it to us in order to invite
            you to our Services).</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We also collect data about you automatically through your use of our Services, as
            described in the &ldquo;Automatically Collected Data&rdquo; section below.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Data we collect from or about you as a Customer, regardless of the source, is
                    considered to be Customer Data. We may combine the information that we collect directly from you with
            information that we collect from third parties and/or automatically. &nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We use Customer Data as follows:</span></ParagraphTag>
            <ul class="c5 lst-kix_l1te5p3d442a-0 start">
              <ListTag class="c1 c3"><span class="c0">For the purposes for which you provided it, such as processing your purchase
                order;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To recognize and authenticate you on our Services;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To provide, maintain, and improve the Services, and the features, services,
                and products available through them;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To troubleshoot the Services and provide technical support;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To send you information about your relationship or transactions with us,
                      account alerts, or other communications, such as newsletters to which you have subscribed or offers for
                discounts or promotions;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To contact you with information or surveys that we believe may be of interest
                to you both regarding our products and those of third parties;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To process and respond to your inquiries or to request your feedback;</span>
              </ListTag>
              <ListTag class="c1 c3"><span class="c0">For internal research, analytics, and reporting;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To comply with the law and protect the safety, rights, property, or security
                of Insent, the Services, our Customers, their end-users, and the general public; and</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To enforce the legal terms that govern your use of our Services.</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">For Customers located in the European Economic Area (&ldquo;EEA&rdquo;), we process
                    Customer Data when we have the legal basis to do so. That is, if you are a Customer in the EEA, we will only
            process your Personal Data (as that term is defined in the GDPR) when:</span></ParagraphTag>
            <ul class="c5 lst-kix_w7z5moo45sc0-0 start">
              <ListTag class="c1 c3"><span class="c0">We need it to provide you our Services pursuant to our contractual
                      obligations to you, or to take steps at your request in anticipation of entering into a contract with
                you;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">You give us consent for a specific purpose, such as email marketing;</span>
              </ListTag>
              <ListTag class="c1 c3"><span class="c0">It satisfies Insent&rsquo;s legitimate interests (which are not overridden by
                      your data protection interests), such as for improving, marketing, and promoting our Services; providing
                customer service; analyzing and improving our Services; and protecting our legal rights; and</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">We need to process your Personal Data to comply with our legal
                obligations.</span></ListTag>
            </ul>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We retain Customer Data for as long as necessary to fulfill the purposes set forth in
                    this Privacy Policy or as long as we are legally required or permitted to do so. For example, we retain our
                    Customers&rsquo; account information for as long as their accounts are active and a reasonable period
                    thereafter in case a Customer decides to re-activate our Services; we also retain Customer Data as necessary
                    to comply with our legal obligations, to resolve disputes, to enforce our agreements, and to continue to
                    develop and improve our Services. Customer Data may persist in copies made for backup and business
            continuity purposes for additional time.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">2. Service Data Collection, Use, and Retention</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We receive Service Data from or on behalf of our Customers. Because of the nature of
                    our Services, this Service Data may contain any type of data, but typically includes name, contact
                    information and company information, such as company name and number of employees. We also collect data
                    about you automatically through your use of our Services, as described in the &ldquo;Automatically Collected
            Data&rdquo; section below.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Data we collect from or about you as an end-user of our Customers&rsquo; websites,
                    applications, or services, regardless of the source, is considered to be Service Data, and we may combine
                    the information that we collect directly from you with information that we collect automatically or other
            information that a Customer provides to us through the Services.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Subject to our contractual obligations, and depending on the particular Services a
            Customer uses, we use Service Data as follows:</span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ul class="c5 lst-kix_7b51liff92x4-0 start">
              <ListTag class="c1 c3"><span class="c0">To provide, maintain, and improve the Services, and the features, services,
                and products available through them;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To troubleshoot the Services and provide technical support;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">For internal research, analytics, and reporting;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To comply with the law and protect the safety, rights, property, or security
                of Insent, the Services, our Customers, you, and the general public;</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">To enforce the legal terms that govern our Customers&rsquo; use of our
                Services; and</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">For other purposes requested or permitted by our Customers.</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We note that, depending on how a Customer uses our Services, they may be able to view
                    your activities on their websites, applications, or services in real-time, such as seeing which webpages of
                    Customer websites you scroll through. Customers may also direct us to record conversations between you and
                    them, including conversations that you have through online chat features of our Services, as well as any
            calls that may be made using our Services.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We retain Service Data consistent with our contractual obligations to our
            Customers.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are an end user of one of our Customer&rsquo;s websites, applications, or
                    services, you should review that Customer&rsquo;s privacy policy to learn more about that Customer&rsquo;s
                    privacy practices, including its collection and use of your data, its legal bases for processing your data,
            and its data retention policies.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">3. Data Disclosure</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We disclose Customer Data and Service Data in the following ways:</span></ParagraphTag>
            <ul class="c5 lst-kix_xv6s20cz6omw-0 start">
              <ListTag class="c1 c3"><span class="c7 c11">Service providers</span><span class="c0">. We disclose data with third
                      parties who perform services on our behalf, including without limitation customer support, data storage,
                data analysis and processing, legal and other professional services.</span></ListTag>
              <ListTag class="c1 c3"><span class="c7 c11">Third-Party Integration Partners</span><span class="c0">. We disclose
                      data with third parties with which our Customers have integrated our Services, such as a customer
                      relationship management (&ldquo;CRM&rdquo;) system. For example, if a Customer provides third-party
                      account credentials to us, some content and/or information in those third-party accounts
                      (&ldquo;Third-Party Account Information&rdquo;) may be transmitted into the Customer&rsquo;s Insent
                      account, and that Third-Party Account Information will be covered by this Privacy Policy as Customer
                Data.</span></ListTag>
              <ListTag class="c1 c3"><span class="c7 c11">Legal compliance and protection of Insent and others</span><span
                class="c0">. We disclose data if required to do so by law or on a good faith belief that such disclosure
                is permitted by this Privacy Policy or reasonably necessary or appropriate for any of the following
                reasons: (i) to comply with legal process; (ii) to enforce this Privacy Policy, or any contracts with
                you, including investigation of potential violations thereof; (iii) to respond to claims that any
                content violates the rights of third parties; (iv) to respond to requests for customer service; and/or
                (v) to protect the rights, property, or personal safety of Insent, our agents and affiliates, our
                Customers, and the public.</span></ListTag>
              <ListTag class="c1 c3"><span class="c7 c11">Business transfers</span><span class="c0">. As we continue to develop our
                      business, we may buy, merge, or partner with other companies. In such transactions (including in
                      contemplation of such transactions, e.g., due diligence) your data may be disclosed. If any of our
                      assets are sold or transferred to a third party, Customer Data and Service Data would likely be among
                the transferred business assets.</span></ListTag>
              <ListTag class="c1 c3"><span class="c7 c11">Affiliated companies</span><span class="c0">. We may disclose data with
                current or future affiliated companies.</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">In addition to the above, if you are a Customer, we disclose Customer Data in the
            following ways:</span></ParagraphTag>
            <ul class="c5 lst-kix_q69rcxq67e4j-0 start">
              <ListTag class="c1 c3"><span class="c7 c11">Consent</span><span class="c0">. We will disclose Customer Data to any
                third parties based on your consent to do so.</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are an end-user of one of our Customer&rsquo;s websites, applications,
                    services, you should review that Customer&rsquo;s privacy policy to learn more about that Customer&rsquo;s
                    privacy practices, including how it discloses your data and, as applicable, transfers your data across
            borders.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">4. Automatically Collected Data</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We collect information about you when you provide it to us and automatically when you
            use our Services.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">Log Data</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We collect information that your browser sends whenever you visit our Website, use
                    our App, or engage with one of our Customers through our Services (&ldquo;Log Data&rdquo;). This Log Data
                    may include information such as your computer&rsquo;s Internet Protocol (&ldquo;IP&rdquo;) address, location
                    information derived from your IP address, browser type, browser version, the pages of our Services or a
                    Customer&rsquo;s service that you visit, the time and date of your visit, the time spent on those pages and
                    other statistics. When you access our Services, or engage with one of our Customers through our Services, by
                    or through a mobile device, we also collect information such as the following: the type of mobile device you
                    use, your mobile device&rsquo;s unique device ID, the IP address of your mobile device, your mobile
                    operating system, the type of mobile Internet browser you use, and your general location information, as
            described further below.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">In addition, we use third-party services, such as Google Analytics, to collect,
                    monitor, and analyze this type of information in order to understand and improve our Services. These
                    third-party service providers have their own privacy policies addressing how they use such information. To
            learn more about Google&rsquo;s privacy practices, please review the</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;ust=1580289059312000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;ust=1580289059313000">Google
                Privacy Policy</a></span><span class="c7">; to prevent Google Analytics from using your information for
            analytics, you may install the Google Analytics Opt-out Browser Add-on by clicking</span><span class="c7"><a
                class="c2"
                href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1580289059313000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1580289059313000">here</a></span><span
                      class="c0">.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c11 c7"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">Cookies</span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c11 c7"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Cookies are files with a small amount of data, which may include an anonymous unique
                    identifier. Cookies are sent to your browser from a website and transferred to your device. You can instruct
                    your browser to refuse all cookies or to indicate when a cookie is being sent. The &ldquo;Help&rdquo;
                    feature on most browsers provides information on how to accept cookies, disable cookies or to notify you
                    when receiving a new cookie. If you are a Customer and do not accept cookies, you may not be able to use
                    some features of our Services and we recommend that you leave them turned on; if you are an end user of one
                    of our Customers and you do not accept cookies, our Customer may not be able to engage with you through our
            Services.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">Please note that you can change your settings to notify you when a cookie is being
                    set or updated, or to block cookies altogether. Please consult the &ldquo;Help&rdquo; section of your
            browser for more information (e.g.,</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies&amp;sa=D&amp;ust=1580289059315000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies&amp;sa=D&amp;ust=1580289059315000">Internet
                Explorer</a></span><span class="c7">;</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;ust=1580289059316000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den&amp;sa=D&amp;ust=1580289059316000">Google
                Chrome</a></span><span class="c7">;</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=http://kb.mozillazine.org/Cookies%23Firefox&amp;sa=D&amp;ust=1580289059317000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://kb.mozillazine.org/Cookies%23Firefox&amp;sa=D&amp;ust=1580289059317000">Mozilla
                Firefox</a></span><span class="c7">; or</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=http://support.apple.com/kb/PH5042&amp;sa=D&amp;ust=1580289059318000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://support.apple.com/kb/PH5042&amp;sa=D&amp;ust=1580289059318000">Apple
                Safari</a></span><span class="c0">).</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We use first-party and third-party cookies, some required for technical reasons in
                    order for our Services to operate while others enable us to track and target the interests of our users to
            enhance the experience on our Services. This is described in more detail below:</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">On Our Website</span></ParagraphTag>
            <ul class="c5 lst-kix_14t2cvy7404z-0 start">
              <ListTag class="c1 c3"><span class="c0">Google Analytics Cookie: _ga, _gid</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Purpose: Unique user tracking</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Description: Used to distinguish unique Customers by assigning a randomly
                generated number as a Customer identifier</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ul class="c5 lst-kix_ozxnjh1t4o1s-0 start">
              <ListTag class="c1 c3"><span class="c0">Insent cookie : insent-user-id</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Purpose: Live chat with users</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Description: Used to identify and initiate conversations with users, when
                they are onsite</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ul class="c5 lst-kix_y6fii05ds7q-0 start">
              <ListTag class="c1 c3"><span class="c7">Hubspot Cookie: hubspotutk</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Purpose: Unique user tracking</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Description:Used to track known users on the site and further use such
                behaviour for automating marketing campaigns</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">In Our App</span></ParagraphTag>
            <ParagraphTag class="c1 c6 c8"><span class="c0"></span></ParagraphTag>
            <ul class="c5 lst-kix_41mle54nuvo3-0 start">
              <ListTag class="c1 c3"><span class="c7">Insent Cookie: </span><span class="c7">insent-publisher-login-token</span>
              </ListTag>
              <ListTag class="c1 c3"><span class="c0">Purpose: Session</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Description: Identifies a Customer&rsquo;s login to the App</span></ListTag>
            </ul>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ul class="c5 lst-kix_1kw5q9tp7x2v-0 start">
              <ListTag class="c1 c3"><span class="c0">Mixpanel Cookie: mp_ID (Partner ID)</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Purpose : Tracking</span></ListTag>
              <ListTag class="c1 c3"><span class="c0">Description : Track usage of Insent app</span></ListTag>
            </ul>
            <ParagraphTag class="c1"><span class="c0">&zwj;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">5. Interest-Based Advertising</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We may allow third parties such as ad exchanges, web analytics vendors, and social
                    networking platforms to use cookies, web beacons, pixel tags, and similar technologies to collect certain
                    data and information about our Customers&rsquo; and other users&rsquo; interaction with us on our Services
                    and elsewhere on the internet (e.g., search and click stream information, browser and device type, time and
                    date, cookie and session ID, and the identity or type of advertisements clicked or scrolled over) and to use
                    this information to display ads for Insent and other entities when Customers or other users use our Services
                    or other websites or applications. These third parties may tie the data collected with information such as
                    the Customer&rsquo;s name, email address, mailing address, and other demographic and behavioral information
                    about you. Such third parties may also tie the device identifier associated with a user&rsquo;s current
                    device to device identifiers associated with other devices that the user may use (e.g., a user&rsquo;s
                    computer, tablet, and phone) in order to measure and target advertisements tailored to the user&rsquo;s
            interests across the user&rsquo;s devices.</span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">We neither have access to nor does this Privacy Policy govern, the use of cookies or
                    other tracking technologies that may be placed on your device to access the Services by such non-affiliated
                    third parties. If you are interested in more information about tailored advertising and how you can
                    generally control cookies and other tracking devices from being put on your computer to deliver tailored
            advertising, you may visit the Network Advertising Initiative (&ldquo;NAI&rdquo;)</span><span class="c7"><a
                class="c2"
                href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059323000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059323000">opt-out
                link</a></span><span class="c7">&nbsp;or the Digital Advertising Alliance
            (&ldquo;DAA&rdquo;)</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059324000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059324000">opt-out
                link</a></span><span class="c7">&nbsp;to opt out of receiving tailored advertising from companies that
        participate in those programs. To opt out of Google Analytics for display advertising or customize Google
            display network ads, visit the</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059324000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN%23!%252F&amp;sa=D&amp;ust=1580289059325000">Google
                Ad Settings page</a></span><span class="c0">. We do not control these opt-out links or whether any
        particular company chooses to participate in these opt-out programs. We are not responsible for any choices
            you make using these mechanisms or the continued availability or accuracy of these mechanisms.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Please note that even if you exercise the opt-out choices above, you may continue to
                    receive advertisements, for example, based on the particular website that you are viewing (i.e.,
                    contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA
            or NAI websites, your opt out may not be effective.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">6. Do-Not-Track Disclosure</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that you can set in certain
                    web browsers. We do not recognize or respond to browser-initiated DNT signals, as the Internet industry is
                    currently still working toward defining exactly what DNT means, what it means to comply with DNT, and a
            common approach to responding to DNT. You can learn more about Do Not Track</span><span class="c7"><a
                class="c2"
                href="https://www.google.com/url?q=http://allaboutdnt.com/&amp;sa=D&amp;ust=1580289059326000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=http://allaboutdnt.com/&amp;sa=D&amp;ust=1580289059326000">here</a></span><span
                      class="c0">.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">7. Your Options and Rights</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">Customer Options and Rights</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are a Customer, we may use your information to contact you with newsletters,
                    marketing or promotional materials and other information that may be of interest to you. You may opt out of
                    receiving any, or all, of these communications from us by following the unsubscribe link or instructions
            provided in any email we send, or by emailing privacy@Insent.ai.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are a Customer located in the EEA or another jurisdiction with similar
                    individual data rights, you may have certain legal rights (subject to applicable exceptions and limitations)
                    to obtain confirmation of whether we hold Personal Data about you, to access that Personal Data, and to
                    obtain its correction, update, amendment, or deletion in appropriate circumstances. You may also have rights
                    to object to our handling of your Personal Data, restrict our processing of your Personal Data, and to
                    withdraw any consent you have provided. To exercise these rights, please contact us as described below with
                    the nature of your request, and we will endeavor to respond to your request as required by applicable law.
                    While we strongly encourage you to first raise any questions or concerns about your Personal Data with us,
            you also have a right to contact the relevant supervisory authority.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">End User Options and Rights</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are an end-user of one of our Customer&rsquo;s websites, applications, or
                    services, we do not contact you with newsletters, marketing, or promotional materials unless you agree to
                    receive such communications from us. If you receive communications from a Customer, you should consult that
            Customer about your options for opting out of receiving that Customer&rsquo;s communications.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you are an end-user of one of our Customer&rsquo;s websites, applications, or
                    services and you are located in the EEA, we will respond to your request to exercise any data subject rights
                    consistent with our applicable contractual obligations to the relevant Customer. We may refer your request
            to the Customer and cooperate with their handling of your request.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">8. Security</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">The security of your information is important to us, and we strive to implement and
                    maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of
                    the information we store, in order to protect it from unauthorized access, destruction, use, modification,
                    or disclosure. However, please be aware that no method of transmission over the internet, or method of
                    electronic storage is 100% secure and we are unable to guarantee the absolute security of the information we
            have collected about you. </span></ParagraphTag>
            <ParagraphTag class="c1 c6"><span class="c0"></span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">9. International Transfer of Personal Data</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Your Personal Data may be transferred to &mdash; and maintained on &mdash; computers
                    located outside of your state, province, country or other governmental jurisdiction where the data
                    protection laws may differ from those in your jurisdiction. If you are located outside the United States and
                    choose to provide Personal Data to us, please note that we transfer Personal Data to the United States and
            process it there pursuant to our Privacy Shield Certification, described below. &nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">10. Links to Other Services</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Our Services may contain links to other websites or online locations that are not
                    operated or controlled by us. If you click on a third-party link, you will be directed to that third
                    party&rsquo;s services. We strongly advise you to review the privacy policy of every site or location you
                    visit. We have no control over and assume no responsibility for the content, privacy policies or practices
            of any third-party sites or services.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7 c11">11. Children&rsquo;s Privacy</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">Our Services are not targeted to be used by anyone under the age of 18
                    (&ldquo;Children&rdquo;), and we do not knowingly collect personally identifiable information (as that term
                    is defined in the Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;)) or Personal Data
                    from Children. If you are a parent or guardian and you learn that your Children have provided us with
                    personally identifiable information or Personal Data, please contact us. If we become aware that we have
                    collected such information from Children without verification of parental consent, we take steps to remove
            that information from our servers.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">13. Changes to this Privacy Policy</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">We reserve the right to update or change our Privacy Policy at any time and you
                    should check this Privacy Policy periodically. Your continued use of the Services after we post any
                    modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your
                    agreement to abide and be bound by the modified Privacy Policy. If we make any material changes to this
                    Privacy Policy, we will notify you as required by law, such as by emailing you or by placing a prominent
            notice on our Website.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">14. How to Contact Us</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c11 c7">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c7">If you have an unresolved privacy or data use concern that we have not addressed
                    satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge)
            at</span><span class="c7"><a class="c2"
                href="https://www.google.com/url?q=https://feedback-form.truste.com/watchdog/request&amp;sa=D&amp;ust=1580289059333000">&nbsp;</a></span><span
                  class="c4"><a class="c2"
                    href="https://www.google.com/url?q=https://feedback-form.truste.com/watchdog/request&amp;sa=D&amp;ust=1580289059334000">https://feedback-form.truste.com/watchdog/request</a></span><span
                      class="c0">.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">If you have any questions or concerns about this Privacy Policy, please contact us
            at: privacy@Insent or 1100 NE Campus Pkwy Suite 200, Seattle, WA 98105.</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c0">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c1"><span class="c18">&nbsp;</span></ParagraphTag>
            <ParagraphTag class="c6 c13"><span class="c11 c17"></span></ParagraphTag>
          </Grid>
        </Grid>
      </Container>
    </PrivacyPolicyContainer>
    <Footer />
    </>
  );
};

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;
