import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { TabletDesktop, Mobile } from 'ui/components/Media';
import styled from 'styled-components';
// import Logo from 'resources/img/footerLogo.png';
import Logo from 'resources/img/Logo.png';
import linkedInImg from 'resources/img/footer-linkedin.png';
import mediumImg from 'resources/img/footer-medium.png';
import twitterImg from 'resources/img/footer-twitter.png';
import angelistImg from 'resources/img/footer-angellist.png';
import { Link as ScrollLink, animateScroll } from 'react-scroll';

const GridItem = styled(Grid)`
  min-width:250px;
  max-width:250px;
`;

const Divider = styled.div`
  max-width: ${p => p.width || '314px'};
  height: 2px;
  object-fit: contain;
  background-color: ${p => p.bgColor || '#0a7ef9'};
`;

const FooterContainer = styled.div`
  padding: 4rem 0;
  max-width: 1120px;
  margin: auto;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 60px 42px;
  }
`;

const BrandWrapper = styled.div`
  /* display: flex;
  align-items: center;
  padding-top: 12px;
  flex-direction: column;
  @media only screen and (max-width: ${p => p.theme.breakpoints.lg - 1}px) {
    justify-content: center;
    margin-bottom: 1rem;
  } */

  /* img {
    margin-right: 31px;
  } */

  p {
    opacity: 0.6;
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #757575;
    margin: 20px 0;
  }

  /* @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    display: flex;
    justify-content: center;
  } */
`;

const Link = styled(({ active, ...others }) => <ScrollLink {...others} />)`
  &&& {
    font-size: 18px;
    color: #757575;
    transition: all 0.3s ease-in-out;
    will-change: all;

    &:hover {
      cursor: pointer;
      color: #0A7EF9;
      
    }

    &:active {
      color: black;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      font-size: 18px;
    }
  }
`;

const ExternalLink = styled.a`
  &&& {
    font-size: 18px;
    color: #757575;
    transition: all 0.3s ease-in-out;
    will-change: all;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: #0A7EF9;
    }

    &:active {
      color: black;
    }

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      font-size: 18px;
    }
  }
`;


const ListHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  padding-bottom: 10px;
  color: #707070;
`;

const ListItem = styled.div`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.44;
  color: #757575;
  opacity: 0.6;

  &:not(:last-child) {
    padding-bottom: 20px;
  }
  &:hover {
    color: #0A7EF9;
    opacity: 1;
  }
`;

const SocialLinkWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    opacity: 0.6;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    justify-content: center;
  }
`;

const BottomFooter = styled.div`
    
    border-top: 1px solid #eee;
    padding: 2rem;
    background: #7575751a;

    @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      padding: 1rem;
    }
`;

const FooterContentWrapper = styled.div`
  max-width: calc(1120px - 4rem);
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: auto;

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const LegalContent = styled.div`
  opacity: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
      margin: 0 auto 1rem;
    }
`;

const BottomFooterItem = styled.div`
  font-weight: normal;
  line-height: 1.44;
    color: #707070;
    opacity: 0.6;
    font-size: 16px;

    @media only screen and (min-width: ${p => p.theme.breakpoints.sm - 1}px) {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
    
  &&& {
a {
    font-size: 16px;
  }
  }
  
`;

const CopyRightText = styled(BottomFooterItem)`
  font-weight: normal;
  line-height: 1.44;
  color: #707070;
  opacity: 0.6;
  font-size: 16px;
  margin-bottom: 1rem;
`;

const Footer = props => {

  const LinkSettings = {
    spy: true,
    smooth: true,
    offset: 30,
    duration: 500,
  };

  return (
    <>
    <FooterContainer>
      <Container>
        <TabletDesktop>
          <Grid container justify="center" spacing={6}>
            <GridItem item sm={6} md={6} lg={3}>
              <BrandWrapper>
                <img src={Logo} alt="logo" height="50px" width="150px" />
                <p>Tools for the world’s best human-centric conversation.</p>
              </BrandWrapper>
            </GridItem>

            <GridItem item sm={6} md={6} lg={3}>
              <ListHeader>Contact us</ListHeader>
              <ListItem><ExternalLink href="mailto:hello@insent.ai?Subject=Hello">hello@insent.ai</ExternalLink></ListItem>
              <ListItem>1100 NE Campus Pkwy Suite 200, Seattle, WA 98105</ListItem>
            </GridItem>

            <GridItem item sm={6} md={6} lg={3}>
              <ListItem><Link to='Home' onClick={() => { window.location.href = "/" }} {...LinkSettings}>Home</Link></ListItem>
              <ListItem>
                <Link
                  to={'Marketing'}
                  onClick={() => { window.location.href = "marketing-team" }}
                >Insent for marketing teams</Link>
              </ListItem>
              <ListItem>
                <Link
                  to={'Sales'}
                  onClick={() => { window.location.href = "sales-team" }}
                >Insent for sales teams</Link>
              </ListItem>
              <ListItem>
                <Link
                  to={'ABM'}
                  onClick={() => { window.location.href = "ABM" }}
                >Insent for ABM</Link>
              </ListItem>
              <ListItem>
                <Link
                  to={'use-cases'}
                  onClick={() => { window.location.href = "use-cases" }}
                >Use Cases</Link>
              </ListItem>
            </GridItem>

            <GridItem item sm={6} md={6} lg={3}>
              <ListItem>
                <Link to='Home' onClick={() => { window.location.href = "why-human-first" }} {...LinkSettings}>
                  Why human-first?
                </Link>
              </ListItem>
              <ListItem>
                <Link
                    to={'qualify-engage'}
                  onClick={() => { window.location.href = "qualify-engage" }}
                >Qualify & engage</Link></ListItem>
              <ListItem>
                <Link
                    to={'converse-context'}
                  onClick={() => { window.location.href = "converse-context" }}
                >Converse & context</Link></ListItem>
              <ListItem><ExternalLink href='https://blog.insent.ai/' target='_blank'>Blog</ExternalLink></ListItem>
              <ListItem><ExternalLink href=' https://help.insent.ai/' target='_blank'>Docs (Beta)</ExternalLink></ListItem>
            </GridItem>
          </Grid>
        </TabletDesktop>
        <Mobile>
              <BrandWrapper>
                <img src={Logo} alt="logo" height="50px" width="150px" />
                <p>Tools for the world’s best human-centric conversation.</p>
              </BrandWrapper>
            <br />
            <ListHeader>Contact us</ListHeader>
            <ListItem><ExternalLink href="mailto:hello@insent.ai?Subject=Hello">hello@insent.ai</ExternalLink></ListItem>
            <ListItem>1100 NE Campus Pkwy Suite 200, Seattle, WA 98105</ListItem>
            <br />
            <ListItem><Link to='Home' onClick={() => { window.location.href = "/" }} {...LinkSettings}>Home</Link></ListItem>
            <ListItem><Link to='Marketing' onClick={() => { window.location.href = "marketing-team" }} {...LinkSettings}>Insent for marketing teams</Link></ListItem>
            <ListItem><Link to='Sales' onClick={() => { window.location.href = "sales-team" }} {...LinkSettings}>Insent for sales teams</Link></ListItem>
            <ListItem>
              <Link
                to={'ABM'}
                onClick={() => { window.location.href = "ABM" }}
              >Insent for ABM</Link>
              
            
              </ListItem>
              <ListItem>
                <Link
                  to={'use-cases'}
                  onClick={() => { window.location.href = "use-cases" }}
                >Use Cases</Link>
              </ListItem>
              <br/>
            <ListItem>
              <Link to='Home' onClick={() => { window.location.href = "why-human-first" }} {...LinkSettings}>
                Why human-first?
                </Link>
            </ListItem>
              <ListItem>
                <Link
                  to={'qualify-engage'}
                  onClick={() => { window.location.href = "qualify-engage" }}
                >Qualify & engage</Link></ListItem>
              <ListItem>
                <Link
                  to={'converse-context'}
                  onClick={() => { window.location.href = "converse-context" }}
                >Converse & context</Link></ListItem>
            <ListItem><ExternalLink href='https://blog.insent.ai/' target='_blank'>Blog</ExternalLink></ListItem>
            <ListItem><ExternalLink href=' https://help.insent.ai/' target='_blank'>Docs (Beta)</ExternalLink></ListItem>
        </Mobile>
        
      </Container>
    </FooterContainer>
    <BottomFooter>
        <TabletDesktop>
          <FooterContentWrapper>
            <LegalContent>
              <BottomFooterItem>Copyright &copy; Insent</BottomFooterItem>
              <BottomFooterItem><Link to='GDPR' onClick={() => { window.location.href = "/gdpr" }} {...LinkSettings}>GDPR</Link></BottomFooterItem>
              <BottomFooterItem><Link to='Terms' onClick={() => { window.location.href = "/terms-of-service" }} {...LinkSettings}>Terms & Service</Link></BottomFooterItem>
              <BottomFooterItem><Link to='Privacy policy' onClick={() => { window.location.href = "/privacy-policy" }} {...LinkSettings}>Privacy policy</Link></BottomFooterItem>
            </LegalContent>
            <SocialLinkWrapper>
              <img
                src={twitterImg}
                alt="twitter"
                height="14px"
                width="18px"
                onClick={() => window.open('https://twitter.com/rarjunpillai')}
              />
              <img
                src={mediumImg}
                alt="medium"
                height="14px"
                width="18px"
                onClick={() => window.open('https://medium.com/insent')}
              />
              <img
                src={linkedInImg}
                alt="linkedin"
                height="16px"
                width="18px"
                onClick={() => window.open('https://www.linkedin.com/company/insent-ai/')}
              />
              <img
                src={angelistImg}
                alt="agellist"
                height="18px"
                width="16px"
                onClick={() => window.open('https://angel.co/company/insent-ai/jobs')}
              />
            </SocialLinkWrapper>
          </FooterContentWrapper>
        </TabletDesktop>
        <Mobile>
          <SocialLinkWrapper>
            <CopyRightText>Copyright &copy; Insent</CopyRightText>
          </SocialLinkWrapper>
          <LegalContent>
            <BottomFooterItem><Link to='GDPR' onClick={() => { window.location.href = "/gdpr" }} {...LinkSettings}>GDPR</Link></BottomFooterItem>
            <BottomFooterItem><Link to='Terms' onClick={() => { window.location.href = "/terms-of-service" }} {...LinkSettings}>Terms & Service</Link></BottomFooterItem>
            <BottomFooterItem><Link to='Privacy policy' onClick={() => { window.location.href = "/privacy-policy" }} {...LinkSettings}>Privacy policy</Link></BottomFooterItem>
          </LegalContent>
          <SocialLinkWrapper>
            <img
              src={twitterImg}
              alt="twitter"
              height="14px"
              width="18px"
              onClick={() => window.open('https://twitter.com/rarjunpillai')}
            />
            <img
              src={mediumImg}
              alt="medium"
              height="14px"
              width="18px"
              onClick={() => window.open('https://medium.com/insent')}
            />
            <img
              src={linkedInImg}
              alt="linkedin"
              height="16px"
              width="18px"
              onClick={() => window.open('https://www.linkedin.com/company/insent-ai/')}
            />
            <img
              src={angelistImg}
              alt="agellist"
              height="18px"
              width="16px"
              onClick={() => window.open('https://angel.co/company/insent-ai/jobs')}
            />
          </SocialLinkWrapper>
        </Mobile>
    </BottomFooter>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
