import React from 'react';
import Hero from './Hero';
import Footer from '../Home/Footer';
import { Helmet } from "react-helmet";

const Home = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Insent’s live chat software enhances, not replaces, real conversations with your customers. Discover smarter marketing automation - book a demo today." />
        <meta name="keywords" content="marketing automation, live chat software, chatbot marketing, demand generation" />
        <title>Live Chat Software for Marketing Teams | Insent.ai Bot</title>
      </Helmet>
      <Hero />
      <Footer />
    </>
  );
};

Home.propTypes = {};

export default Home;
