import React from 'react';
import { Container as MuiContainer , Grid } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import roiHero1x from 'resources/img/roiHero1x.png';
import roiHero3x from 'resources/img/roiHero3x.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';
import Image from 'ui/components/Image';
import Header from '../Home/Header';
import Button from 'ui/components/Button';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;

  min-height: 100vh;
  align-items: flex-end;
  display: flex;
  background: ${p => p.theme.palette.background.five};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 158px 0 0;

    button {
      text-align: center;
    }
  }
`;


const HeroContentWrapper = styled(animated.div)`
  display: flex;
  height: 100%;
  height: 100%;
  padding: 0 0 0 0;
  width: 100%;
  max-width: 550px;
  margin: auto;

  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    margin-top: 0px;
    justify-content: center;
  }

  &&& {
    input[type='submit'] {
      background: ${p => p.theme.palette.orange.one};
    }
  }
`;

const Hero = props => {
    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
        >
            
            <HeroContainer>
                <Header />
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <HeroContentWrapper>
                                  <Text fontSize="48px" smFontSize="23px" fontWeight={700} mdTextAlign="center" lineHeight="144%">
                                    Calculate the Impact of Chat On Your Website 
                                  </Text>
                                  <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginTop="25px" marginBottom="30px" smMarginBottom="30px" maxWidth="530px" opacity="0.8" lineHeight="170.3%">
                                    78% of customers buy from the first responder. And once you respond, sales conversions are 391% higher in the first minute.  Those are incredible results, and it’s why B2B marketing and sales teams are adopting conversational marketing on their website more than ever before.
                                  </Text>
                                  <Button height="50px" fontWeight="600" fontSize="14px" padding="15px 40px">Calculate Now</Button>
                            </HeroContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Image height="100%" width="100%" maxWidth="610px" margin="0 auto" mdPaddingTop="60px" mdPaddingBottom="60px" display="flex" hdSrc={roiHero3x} src={roiHero1x} />
                        </Grid>
                    </Grid>
                </Container>
            </HeroContainer>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
