import React from 'react';
import { Container as MuiContainer , Grid} from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import CallToAction from 'ui/components/CallToAction';
import heroImage from 'resources/img/pabloTelescope.png';
import Text from 'ui/components/Text';
import theme from 'styles/theme';

const Container = styled(MuiContainer)`
  &&& {
    max-width: 1148px;
  }
`;

const HeroAnimatedImg = styled(animated.img)`
  width: 100%;
  max-width: 510px;
  padding: 2rem 0;
  display: block;
  margin: auto;
   @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    padding: 2rem 0;
    max-width: 500px;
  }

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 2rem 0;
    max-width: 475px;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
  padding: 0 0 5rem;

  min-height: calc(100vh  - 6rem);
  align-items: center;
  display: flex;
  background: ${p => p.theme.palette.blue.one};

  @media only screen and (max-width: ${p => p.theme.breakpoints.sm - 1}px) {
    padding: 1rem 0;

    button {
      text-align: center;
    }
  }
`;

const HeroContentWrapper = styled(animated.div)`
  display: flex;
  align-items: center; 
  height: 100%;
  
  @media only screen and (max-width: ${p => p.theme.breakpoints.md - 1}px) {
    display: block;
    height: 100%;
    padding: 0 0 0 0;
    width: 100%;
    max-width: 550px;
    margin: auto;
  }
`;

const Hero = props => {
    const [isContentVisible, setContentIsVisible] = React.useState(false);

    const leftCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(-200px)`,
    });
    const rightCardStyleProps = useSpring({
        opacity: isContentVisible ? 1 : 0,
        transform: isContentVisible ? `translateX(0px)` : `translateX(200px)`,
    });

    return (

        <Waypoint
            scrollableAncestor={window}
            topOffset="10%"
            bottomOffset="35%"
            onEnter={() => setContentIsVisible(true)}
            onLeave={() => setContentIsVisible(false)}
        >
            
            <HeroContainer>
                
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <HeroContentWrapper style={leftCardStyleProps}>
                                <div>

                    <Text fontSize="16px" color={theme.palette.text.three} mdTextAlign="center" marginBottom="10px" smMarginBottom="14px">Qualify & Engage</Text>
                    <Text fontSize="40px" smFontSize="23px" fontWeight={700} mdTextAlign="center">
                       Know who's actually qualified and ready to chat.
                                    </Text>
                    <Text fontSize="18px" smFontSize="13px" mdTextAlign="center" marginTop="14px" marginBottom="50px" smMarginBottom="36px" >
                    Insent does real-time lead qualification, so you know who you should talk to.
                                    </Text>
                    <CallToAction justify="left" mdJustify="center" />
                                </div>
                            </HeroContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HeroAnimatedImg style={rightCardStyleProps} src={heroImage} alt="Hero" />
                        </Grid>
                    </Grid>
                </Container>
            </HeroContainer>
        </Waypoint>
    );
};

Hero.propTypes = {};

export default Hero;
